<template>
  <div id="SafetyPanel">
    <feature-header name="Safety" :score="vehicle.Safety_Score" />
    <div class="row">
      <div class="col">
        <h4>
          NHTSA Safety Rating
          <stars
            v-if="vehicle.NHTSA_Overall_Rating"
            :number="vehicle.NHTSA_Overall_Rating"
          />
          <span class="not-tested" v-show="!vehicle.NHTSA_Overall_Rating"
            >Not Tested</span
          >
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="d-none d-lg-block col-lg-2 pt-lg-2 pl-lg-3">
        <img src="~Images/safety_icon.svg" />
      </div>
      <div class="col-6 col-lg-5" v-for="(category, idx) in safetyCategories" :key="idx">
        <div class="row">
          <h4 class="col">{{ category.name }}</h4>
        </div>
        <div class="row" v-for="(feature, idx2) in category.features" :key="idx2">
          <div class="col">
            <YesNoOptional :expr="feature.availability" /> {{ feature.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const FeatureHeader = () => import("Components/VehicleDetail/FeatureHeader");
const YesNoOptional = () => import("Components/YesNoOptional");
const Stars = () => import("Components/Stars");
export default {
  props: {
    vehicle: Object,
  },
  computed: {
    safetyCategories() {
      return [
        {
          name: "Warnings",
          features: [
            {
              name: "Forward collision warning",
              availability: this.vehicle.Forward_Collision_Warning || "No",
            },
            {
              name: "Lane departure warning",
              availability: this.vehicle.Lane_Departure_Warning || "No",
            },
          ],
        },
        {
          name: "Braking",
          features: [
            {
              name: "Crash imminent braking",
              availability: this.vehicle.Crash_Imminent_Braking || "No",
            },
            {
              name: "Dynamic brake support",
              availability: this.vehicle.Dynamic_Brake_Support || "No",
            },
          ],
        },
      ];
    },
  },
  components: {
    FeatureHeader,
    Stars,
    YesNoOptional,
  },
};
</script>

<style scoped lang="scss">
h4 {
  color: #162b32;
  font-size: 1rem;
}
.not-tested {
  color: #ff4838;
}
::v-deep .yes-no-optional {
  position: relative;
  top: -2px;
  padding-right: 3px;
}
</style>
